// src/store/index.js
import { createStore } from 'vuex';
import axios from 'axios';
import { API_BASE_URL } from '../config/apiConfig';

export default createStore({
  state: {
    numbers: ''
  },
  mutations: {
    setNumbers(state, numbers) {
      state.numbers = numbers;
    }
  },
  actions: {
    async loadNumbers({ commit }) {
      try {
        const response = await axios.get(`${API_BASE_URL}php/count.php`);
        commit('setNumbers', response.data);
      } catch (error) {
        console.error('Error fetching numbers:', error);
      }
    }
  }
});
