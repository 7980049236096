<template>
  <div>
    <h1>Numbers from PHP Script</h1>
    <div v-html="numbers"></div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { API_BASE_URL } from '../config/apiConfig'; // Import the global variable

export default {
  computed: {
    ...mapState(['numbers'])
  },
  mounted() {
    this.loadNumbers();
    console.log('API Base URL:', API_BASE_URL); // Example usage of the global variable
  },
  methods: {
    ...mapActions(['loadNumbers'])
  }
};
</script>

<style scoped>
/* Add any necessary styling here */
</style>
