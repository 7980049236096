<template>
  <div class="container">
    <h1>Contact</h1>
    <p>This is the contact page.</p>
  </div>
</template>

<script>
export default {
  name: 'ContactPage'
}
</script>

<style scoped>
</style>
