<template>
  <div class="container">
    <h1>About</h1>
    <p>This is the about page.</p>
  </div>
</template>

<script>
export default {
  name: 'AboutPage'
}
</script>

<style scoped>
</style>
